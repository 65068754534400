import React from "react";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import LineIcon from "react-lineicons";


const orderButton = {
  backgroundColor: "rgb(250, 185, 64)", 
  border: "2px solid rgb(250, 185, 64)", 
  color: "rgb(255, 255, 255)",
  width: "100%",
  justifyContent: "center",
  textAlign: "center",
  textDecoration: "none",
  fontSize: "22px"
}

const phoneUrl = {
  color: "#fab940",
  textAlign: "center",
  fontSize: "35px",
  textDecoration: "none",
  paddingLeft: "15px"
}

const stores = [{
  name: "1) R&B's Pizza (Bellevue)",
  address: "421 Lincoln Ave.",
  address2: "Bellevue, Pennsylvania. 15202",
  phone: "(412) 732-2555",
  url: "https://rbspizza.hungerrush.com/Order/Menu/1#Deals"
}]

function Menu() {
  return (
    <Layout>
      <div className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Online Order" />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
              {stores.map((store, i) => (
                <div key={i} className="col-lg-4 col-md-6 col-12 mt-30">
                  
                  <div className="mi-service">
                    <span className="mi-service-icon">
                      <LineIcon name={"pizza"} tag={"p"}> 
                        <a href={`tel:${store.phone}`} style={phoneUrl}>{store.phone}</a> 
                      </LineIcon>
                    </span>
                    
                    <h5>{store.name}</h5>
                    <p style={{fontSize: "18px"}}>{store.address} <br/> {store.address2}</p>
                    
                    <br/>
                    <a href={store.url} rel="opener">
                      <button style={orderButton}> Order Online </button>
                    </a>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Menu;
